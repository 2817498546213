import React from 'react';

function HowCitrixCreatedASimpleScalableProductDocumentationExperienceWithHashout() {
    return (
        <div style={{height: "100vh"}}>
            <object data="../../How-Citrix-created-a-simple-scalable-Product-Documentation-experience-with-Hashout.pdf#toolbar=0" type="application/pdf" width="100%" height="100%">
                <a href="../../How-Citrix-created-a-simple-scalable-Product-Documentation-experience-with-Hashout.pdf"></a>
            </object>
        </div>
    )
}

export default HowCitrixCreatedASimpleScalableProductDocumentationExperienceWithHashout
